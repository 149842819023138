import React, { useState } from "react";
import SEO from "../../Components/SEO";
import { classNames } from "../../utils";
import ConferenceOdds from "./ConferenceOdds";
import OverallOdds from "./OverallOdds";

const tabs = [
  { id: 1, name: "Overall", href: "#", current: false },
  // { id: 2, name: "AFC", href: "#", current: false },
  // { id: 3, name: "NFC", href: "#", current: true },
];

function PlayoffOdds() {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <div>
      <SEO
        title="Playoff Odds"
        description="View playoff odds in the context of best ball."
        image="https://www.bestballrankings.com/static/media/Logo.97250659a2f6a1b83815.png"
      />

      <div className="border-b border-gray-200 pb-5 sm:pb-0 w-full">
        <h3 className="text-lg font-bold leading-6 text-gray-900">
          Playoff Odds
        </h3>
        <div className="mt-3 sm:mt-4">
          <div className="sm:hidden">
            <label htmlFor="current-tab" className="sr-only">
              Select a tab
            </label>
            <select
              id="current-tab"
              name="current-tab"
              onChange={(e) => setActiveTab(parseInt(e.target.value, 10))}
              className="block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
            >
              {tabs.map((tab) => (
                <option value={tab.id} key={tab.name}>
                  {tab.name}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={classNames(
                    tab.id === activeTab
                      ? "border-sky-500 text-sky-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div>
        {activeTab === 1 && <OverallOdds />}
        {activeTab === 2 && <ConferenceOdds conference="afc" />}
        {activeTab === 3 && <ConferenceOdds conference="nfc" />}
      </div>
    </div>
  );
}

export default PlayoffOdds;
