import React from "react";
import Table from "./Table";
import SEO from "../../Components/SEO";

function Rankings() {
  return (
    <div>
      <SEO
        title="Download Rankings"
        description="Download the best ball rankings that have won over $75K over just the last two seasons. Upload directly to Underdog."
        image="https://www.bestballrankings.com/static/media/Logo.97250659a2f6a1b83815.png"
      />
      <div className="pb-3 flex items-center justify-between w-full">
        <h3 className="text-lg font-bold leading-6 text-gray-900">Rankings</h3>
        <p className="text-xs font-medium leading-6 text-gray-500">
          Last Updated: 11/14/24
        </p>
      </div>
      <div>
        <Table />
      </div>
    </div>
  );
}

export default Rankings;
