export const rankingsList = [
  {
    id: "nfl-underdog-ranks",
    name: "NFL Underdog Ranks",
    asset: "nfl_underdog.csv",
    type: "underdog",
  },
  // {
  //   id: "nfl-underdog-ranks-superflex",
  //   name: "Underdog SuperFlex Ranks",
  //   asset: "ud_superflex.csv",
  //   type: "underdog",
  // },
  // {
  //   id: "drafters",
  //   name: "Drafters",
  //   asset: "drafters.csv",
  //   type: "drafters",
  // },
  // {
  //   id: "draftkings",
  //   name: "Draft Kings Rankings",
  //   asset: "draftkings.csv",
  //   type: "draftkings",
  // },
  // {
  //   id: "nfl-underdog-ranks-eliminator",
  //   name: "Underdog Eliminator Ranks",
  //   asset: "ud_eliminator.csv",
  //   type: "underdog",
  // },
];
