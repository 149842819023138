import React, { useContext, useEffect, useState } from "react";
import Papa from "papaparse";
import { AuthContext } from "../../contexts";
import { useNavigate } from "react-router";
import { ROUTE_LOGIN } from "..";
import { getCheckoutUrl } from "../../utils";
import Spinner from "../../Components/Spinner";

function ConferenceOdds({ conference }) {
  const [odds, setOdds] = useState(null);
  const { authenticated, isPurchased } = useContext(AuthContext);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCSV = async (path) => {
      try {
        const response = await fetch(
          `${process.env.PUBLIC_URL}/assets/${path}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("text/csv")) {
          throw new TypeError("The fetched file is not in CSV format!");
        }
        const csvText = await response.text();
        Papa.parse(csvText, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            setOdds(results.data);
          },
        });
      } catch (error) {
        console.error("Failed to fetch the CSV: ", error);
      }
    };

    fetchCSV(`${conference}_playoff_odds.csv`);
  }, [conference]);

  const handleCheckout = async () => {
    setSubmitting(true);
    const url = await getCheckoutUrl();

    if (url) {
      window.location.href = url;
    }
  };

  return (
    <div className="mt-5 overflow-hidden rounded-lg shadow-sm border border-gray-200">
      <div className="min-w-full divide-y divide-gray-300">
        <div className="bg-gray-50">
          <div className="grid grid-cols-10 py-2.5 px-3.5 gap-x-2">
            <div className="hidden sm:block sm:col-span-1 text-left text-xs uppercase font-semibold text-gray-500">
              Seed
            </div>
            <div className="col-span-10 sm:col-span-2 text-left text-xs uppercase font-semibold text-gray-500">
              Team
            </div>
            <div className="hidden sm:block sm:col-span-1 text-left text-xs uppercase font-semibold text-gray-500">
              Record
            </div>
            <div className="hidden sm:block sm:col-span-1  text-left text-xs uppercase font-semibold text-gray-500">
              DK Playoff Odds
            </div>
            <div className="hidden sm:block sm:col-span-1  text-left text-xs uppercase font-semibold text-gray-500">
              NYT Playoff Odds
            </div>
            <div className="hidden sm:block sm:col-span-1  text-left text-xs uppercase font-semibold text-gray-500">
              1 Seed Odds
            </div>
            <div className="hidden sm:block sm:col-span-1  text-left text-xs uppercase font-semibold text-gray-500">
              First Round Bye Odds
            </div>
            <div className="hidden sm:block sm:col-span-1  text-left text-xs uppercase font-semibold text-gray-500">
              Super Bowl Odds
            </div>
            <div className="hidden sm:block sm:col-span-1  text-left text-xs uppercase font-semibold text-gray-500">
              DK Super Bowl Odds
            </div>
          </div>
        </div>
        <div className="divide-y divide-gray-200 bg-white">
          {odds &&
            odds.map((team, index) => {
              return (
                <div
                  className="grid grid-cols-10 text-left w-full px-3.5 py-3 items-center"
                  key={index}
                >
                  <div className="col-span-10 sm:col-span-1 text-left text-xs sm:text-sm font-normal text-gray-500">
                    {team.seed}
                  </div>
                  <div className="col-span-10 sm:col-span-2 text-left text-sm font-semibold text-gray-900">
                    {team.team}
                  </div>
                  <div className="col-span-10 sm:col-span-1 text-left text-sm text-gray-700">
                    <span className="sm:hidden text-xs text-gray-500 font-semibold mr-1">
                      Record:
                    </span>
                    {team.record}
                  </div>
                  <div className="col-span-10 sm:col-span-1  text-left text-sm text-gray-700">
                    <span className="sm:hidden text-xs text-gray-500 font-semibold mr-1">
                      Draft Kings Odds:
                    </span>
                    {team.oddsDK}
                  </div>
                  <div className="col-span-10 sm:col-span-1  text-left text-sm text-gray-700">
                    <span className="sm:hidden text-xs text-gray-500 font-semibold mr-1">
                      NYT Odds:
                    </span>
                    {team.oddsNYT}
                  </div>
                  <div className="col-span-10 sm:col-span-1  text-left text-sm text-gray-700">
                    <span className="sm:hidden text-xs text-gray-500 font-semibold mr-1">
                      1 Seed Odds:
                    </span>
                    {team.oddsOneSeed}
                  </div>
                  <div className="col-span-10 sm:col-span-1  text-left text-sm text-gray-700">
                    <span className="sm:hidden text-xs text-gray-500 font-semibold mr-1">
                      First Round Bye:
                    </span>
                    {team.frb}
                  </div>
                  <div className="col-span-10 sm:col-span-1  text-left text-sm text-gray-700">
                    <span className="sm:hidden text-xs text-gray-500 font-semibold mr-1">
                      Super Bowl %
                    </span>
                    {team.sbPerc}
                  </div>
                  <div className="col-span-10 sm:col-span-1  text-left text-sm text-gray-700">
                    <span className="sm:hidden text-xs text-gray-500 font-semibold mr-1">
                      DK Super Bowl Odds:
                    </span>
                    {team.sbdk}
                  </div>
                </div>
              );
            })}
          {!authenticated || !isPurchased ? (
            <div className="relative isolate overflow-hidden bg-white px-6 py-24 text-center sm:px-16">
              <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-gray-900">
                Get my player rankings and start winning money!
              </h2>
              <p className="mx-auto mt-4 max-w-xl text-base leading-8 text-gray-500">
                Get the rankings that have won over $50K for only $14.99.
                Download and upload directly into Underdog for easy use.
              </p>
              <div className="mt-4 flex items-center justify-center gap-x-6">
                <button
                  type="button"
                  onClick={() => {
                    authenticated ? handleCheckout() : navigate(ROUTE_LOGIN);
                  }}
                  className="inline-flex items-center rounded-md bg-sky-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500"
                >
                  {submitting ? <Spinner color="#FFFFFF" /> : "Get Rankings →"}
                </button>
              </div>
              <svg
                viewBox="0 0 1024 1024"
                className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
                aria-hidden="true"
              >
                <circle
                  cx="512"
                  cy="512"
                  r="512"
                  fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
                  fillOpacity="0.7"
                />
                <defs>
                  <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                    <stop stopColor="#7775D6" />
                    <stop offset="1" stopColor="#E935C1" />
                  </radialGradient>
                </defs>
              </svg>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ConferenceOdds;
